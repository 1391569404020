import { TIME_FORMAT_DATE, TIME_FORMAT_DATE_SECONDARY } from 'configs/const';
import { toDate as fnsToDate, format, isValid, max, min, parse } from 'date-fns';
import { formatInTimeZone, FormatOptionsWithTZ } from 'date-fns-tz';

export type DateValue = Date | string | number | null | undefined;

export const isDateLike = (value: any): value is string | number | Date => {
  if (!value) return false;
  if (typeof value === 'string') {
    return isValid(new Date(value));
  }
  return isValid(fnsToDate(value));
};

export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};

export const minDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return min(values);
};
export const maxDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return max(values);
};

export const dateFormat = (
  value: DateValue,
  _format: Parameters<typeof format>[1] = TIME_FORMAT_DATE,
  options?: Parameters<typeof format>[2],
) => {
  if (isDateLike(value)) {
    return format(convertToDate(value), _format, options);
  }
  return '--';
};

export const dateFormatZones = (
  value: DateValue,
  _format: string = TIME_FORMAT_DATE,
  _options?: FormatOptionsWithTZ & { zone?: string },
) => {
  if (!isDateLike(value)) {
    return '--';
  }
  const { zone = 'UTC', ...options } = _options || {};

  return formatInTimeZone(convertToDate(value), zone, _format, options);
};

export const convertStringToDate = (
  value: string | number | boolean | null | undefined,
  dateFormatIn = TIME_FORMAT_DATE_SECONDARY,
  dateFormatOut = TIME_FORMAT_DATE,
) => {
  if (typeof value !== 'string') {
    return '';
  }

  const date = parse(value, dateFormatIn, new Date());

  if (!isValid(date)) {
    return '';
  }

  return format(date, dateFormatOut);
};
