import { IBond, IDataGelemBond, IDataGrossYieldPanelItem } from '__generated__/api';
import { sortRatings } from 'utils/app-helpers';

export const getColorByGroupName = <T extends { ratingGroup?: string | null }>(record: T) => {
  switch (record.ratingGroup) {
    case 'Aaa.il': {
      return '#0000ff';
    }

    case 'Aa.il': {
      return '#008D09';
    }
    case 'Aa1.il': {
      return '#005C06';
    }
    case 'Aa2.il': {
      return '#008D09';
    }
    case 'Aa3.il': {
      return '#2BD036';
    }

    case 'A.il': {
      return '#4F39BA';
    }
    case 'A1.il': {
      return '#120551';
    }
    case 'A2.il': {
      return '#4F39BA';
    }
    case 'A3.il': {
      return '#7B6ACD';
    }

    case 'Baa.il': {
      return '#FF8D35';
    }
    case 'Baa1.il': {
      return '#B44E00';
    }
    case 'Baa2.il': {
      return '#FF8D35';
    }
    case 'Baa3.il': {
      return '#FFAF72';
    }

    case 'Ba.il': {
      return '#04184F';
    }
    case 'Ba1.il': {
      return '#04184F';
    }
    case 'Ba2.il': {
      return '#3658B6';
    }
    case 'Ba3.il': {
      return '#6782CA';
    }

    case 'B.il': {
      return '#00280C';
    }
    case 'B1.il': {
      return '#00280C';
    }
    case 'B2.il': {
      return '#145F2B';
    }
    case 'B3.il': {
      return '#43b565';
    }

    case 'Caa.il': {
      return '#90EE90';
    }

    case 'Ca.il': {
      return '#C00F73';
    }
    case 'Ca1.il': {
      return '#7D0047';
    }
    case 'Ca2.il': {
      return '#C00F73';
    }
    case 'Ca3.il': {
      return '#ec7bbb';
    }

    case 'C.il': {
      return '#EF1313';
    }
    case 'C1.il': {
      return '#9C0000';
    }
    case 'C2.il': {
      return '#EF1313';
    }
    case 'C3.il': {
      return '#f38989';
    }

    case 'P.il': {
      return '#EFC213';
    }
    case 'P1.il': {
      return '#9C7C00';
    }
    case 'P2.il': {
      return '#EFC213';
    }
    case 'P3.il': {
      return '#feea9d';
    }

    default: {
      return '#470047';
    }
  }
};

export type BaseBondPointMeta = {
  symbol: number;
  name: string;
  id: string;
};
export type BaseBondPointDataSeries = {
  x: number;
  y: number;
  meta: BaseBondPointMeta;
};
export type BaseBondPointSeries = {
  name: string;
  data: Array<BaseBondPointDataSeries>;
};

type BondPointSeriesData = Pick<IBond, 'grossDuration' | 'symbol' | 'name' | 'id'>;

interface MakeBondPointSeriesOptions<T> {
  getSeriesName: (row: T) => string;
  getY: (row: T) => number;
}
export const makeBondPointSeries = <T extends BondPointSeriesData>(
  rows: T[],
  options: MakeBondPointSeriesOptions<T>,
) => {
  const { getSeriesName, getY } = options;
  const data = rows.reduce((acc: Record<string, BaseBondPointSeries>, row) => {
    const y = getY(row);

    if (y > 12) {
      return acc;
    }

    const key = getSeriesName(row);

    if (!key) {
      return acc;
    }

    if (!acc[key]) {
      acc[key] = {
        name: String(key),
        data: [],
      };
    }

    acc[key].data.push({
      x: row.grossDuration || 0,
      y,
      meta: { symbol: row.symbol || 0, name: row.name || '', id: row.id || '' },
    });

    return acc;
  }, {});

  return Object.values(data).sort((a, b) => sortRatings(a.name, b.name));
};

export const makeBondGelemSources = (rows: IDataGelemBond[]) => {
  const mapMidroogRanks = new Map<string, { id: string; title: string }>();
  const mapMidroogOutlook = new Map<string, { id: string; title: string }>();

  const mapMaalotRanks = new Map<string, { id: string; title: string }>();
  const mapMaalotOutlook = new Map<string, { id: string; title: string }>();

  const mapSymbols = new Map<number, { id: string; name: string; symbol: number }>();

  const mapIssuers = new Map<string, { id: string; title: string; issuerNum: number }>();

  const mapEquityType = new Map<string, { id: string; title: string }>();
  const mapBranchName = new Map<string, { id: string; title: string }>();

  rows.forEach((row) => {
    if (row.symbol && !mapSymbols.has(row.symbol)) {
      mapSymbols.set(row.symbol, {
        id: String(row.id),
        name: String(row.name),
        symbol: row.symbol,
      });
    }

    if (row.issuerNum && !mapIssuers.has(row.issuerNum)) {
      mapIssuers.set(row.issuerNum, {
        id: String(row.issuerName),
        title: String(row.issuerName),
        issuerNum: Number(row.issuerNum),
      });
    }

    if (row.bondMidroogRank && !mapMidroogRanks.has(row.bondMidroogRank)) {
      const key = row.bondMidroogRank;
      mapMidroogRanks.set(key, { id: key, title: key });
    }
    if (row.bondMidroogOutlook && !mapMidroogOutlook.has(row.bondMidroogOutlook)) {
      const key = row.bondMidroogOutlook;
      mapMidroogOutlook.set(key, { id: key, title: key });
    }

    if (row.bondMaalotRank && !mapMaalotRanks.has(row.bondMaalotRank)) {
      const key = row.bondMaalotRank;
      mapMaalotRanks.set(key, { id: key, title: key });
    }
    if (row.bondMaalotOutlook && !mapMaalotOutlook.has(row.bondMaalotOutlook)) {
      const key = row.bondMaalotOutlook;
      mapMaalotOutlook.set(key, { id: key, title: key });
    }
    if (row.equityTypeName && !mapEquityType.has(row.equityTypeName)) {
      const key = row.equityTypeName;
      mapEquityType.set(key, { id: key, title: key });
    }
    if (row.branchName && !mapBranchName.has(row.branchName)) {
      const key = row.branchName;
      mapBranchName.set(key, { id: key, title: key });
    }
  });

  return {
    midroogRanks: Array.from(mapMidroogRanks.values()),
    midroogOutlook: Array.from(mapMidroogOutlook.values()),

    maalotRanks: Array.from(mapMaalotRanks.values()),
    maalotOutlook: Array.from(mapMaalotOutlook.values()),

    symbols: Array.from(mapSymbols.values()),
    issuers: Array.from(mapIssuers.values()),
    equityTypes: Array.from(mapEquityType.values()),
    branches: Array.from(mapBranchName.values()),
  };
};
export const makeBondGrossSources = (rows: IDataGrossYieldPanelItem[]) => {
  const mapSymbols = new Map<number, { id: string; name: string; symbol: number }>();
  const mapIssuers = new Map<string, { id: string; title: string; issuerNum: number }>();
  const mapEquityType = new Map<string, { id: string; title: string }>();
  const mapBranches = new Map<string, { id: string; title: string }>();

  const mapMidroogRatingGroups = new Map<string, { id: string; title: string; rank: number }>();
  const mapMidroogRatings = new Map<
    string,
    { id: string; title: string; rank: number; group: string }
  >();

  rows.forEach((row) => {
    if (row.symbol && !mapSymbols.has(row.symbol)) {
      mapSymbols.set(row.symbol, {
        id: String(row.id),
        name: String(row.name),
        symbol: row.symbol,
      });
    }
    if (row.issuerNum && !mapIssuers.has(row.issuerNum)) {
      mapIssuers.set(row.issuerNum, {
        id: String(row.issuerName),
        title: String(row.issuerName),
        issuerNum: Number(row.issuerNum),
      });
    }
    if (row.equityTypeName && !mapEquityType.has(row.equityTypeName)) {
      const key = row.equityTypeName;
      mapEquityType.set(key, { id: key, title: key });
    }
    if (row.branchName && !mapBranches.has(row.branchName)) {
      const key = row.branchName;
      mapBranches.set(key, { id: key, title: key });
    }
    if (row.midroogRatingGroup && !mapEquityType.has(row.midroogRatingGroup)) {
      const key = row.midroogRatingGroup;
      mapMidroogRatingGroups.set(key, {
        id: key,
        title: key,
        rank: row.midroogRatingGroupRank ?? 0,
      });
    }
    if (row.midroogRating && !mapEquityType.has(row.midroogRating)) {
      const key = row.midroogRating;
      mapMidroogRatings.set(key, {
        id: key,
        title: key,
        rank: row.midroogRatingGroupRank ?? 0,
        group: row.midroogRatingGroup || 'unknown',
      });
    }
  });

  return {
    symbols: Array.from(mapSymbols.values()),
    issuers: Array.from(mapIssuers.values()),
    equityTypes: Array.from(mapEquityType.values()),
    branches: Array.from(mapBranches.values()),
    midroogRatingGroups: Array.from(mapMidroogRatingGroups.values()),
    midroogRatings: Array.from(mapMidroogRatings.values()),
  };
};

type MakeBondGrossRatingNamesOptions = {
  sources: Array<{ title: string; group: string }>;
  selected: string[];
  selectedGroups: string[];
};
export const makeBondGrossRatingNames = (options: MakeBondGrossRatingNamesOptions) => {
  const { sources, selected, selectedGroups } = options;
  const items = sources.filter(({ group, title }) =>
    selected.length === 0 ? selectedGroups.includes(group) : selected.includes(title),
  );

  return Array.from(new Set(items.map(({ title, group }) => [title, group]).flat()));
};
