import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBondGelemYieldReport, SELECT_BOND_GELEM_YIELD_REPORT } from 'services/bonds-helper';
import { dividedBy } from 'utils/app-helpers';
import { multiplyBondRowItems } from 'utils/bonds';
import { dateFormat } from 'utils/dates';
import { API, parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { PREFIX } from './helpers';
import { selectorsReportYieldExtreme } from './selectors';

export const actionReportYieldExtremeFetch = createAsyncThunk<
  Array<IBondGelemYieldReport>,
  void | { initDate: string },
  AppAsyncThunkConfig
>(`${PREFIX}/actionReportYieldExtremeFetch`, async (_, { getState }) => {
  try {
    const { date, value } = selectorsReportYieldExtreme.filters(getState());
    const { data } = await API.api.bondsHelperGetGelemBondYieldItemsDynamicCreate({
      select: SELECT_BOND_GELEM_YIELD_REPORT,
      date: dateFormat(date, 'yyyy-MM-dd'),
      minGrossYieldToMaturity: dividedBy(value[0]),
      maxGrossYieldToMaturity: dividedBy(value[1]),
    });

    return data.items.map(multiplyBondRowItems) as unknown as Array<IBondGelemYieldReport>;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});
