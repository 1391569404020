import { enUS, he, Locale } from 'date-fns/locale';
import { useLanguage } from 'hooks/use-language';
import React, { memo, useContext, useMemo } from 'react';

interface ContextValue {
  dateFns: Locale;
  locale: string;
}
const Context = React.createContext<null | ContextValue>(null);

export const useLibsLocalization = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('LocalizationProvider is not defined');
  }

  return context;
};

interface Props {
  children: React.ReactNode;
}
export const LocalizationProvider = memo<Props>(({ children }) => {
  const { language } = useLanguage();

  const value = useMemo<ContextValue>(() => {
    if (language?.culture === 'HE') {
      return { dateFns: he, locale: 'he' };
    }

    return { dateFns: enUS, locale: 'en' };
  }, [language]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
});
