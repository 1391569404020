import { multiplyBy100 } from './numbers';

export const multiplyBondRowItems = <T extends Record<string, any>>(row: T) => {
  return {
    ...row,
    ...(Object.hasOwn(row, 'grossYieldToMaturity')
      ? { grossYieldToMaturity: multiplyBy100(row['grossYieldToMaturity']) }
      : {}),
    ...(row['grossYieldToMaturityDeltaByDuration']
      ? {
          grossYieldToMaturityDeltaByDuration: multiplyBy100(
            row['grossYieldToMaturityDeltaByDuration'],
          ),
        }
      : {}),
    ...(row['calcSynDiffAvgMonth']
      ? { calcSynDiffAvgMonth: multiplyBy100(row['calcSynDiffAvgMonth']) }
      : {}),
    ...(row['calcSynDiffAvgMonthMove']
      ? { calcSynDiffAvgMonthMove: multiplyBy100(row['calcSynDiffAvgMonthMove']) }
      : {}),
    ...(row['calcSynDiffAvgWeek']
      ? { calcSynDiffAvgWeek: multiplyBy100(row['calcSynDiffAvgWeek']) }
      : {}),
    ...(row['calcSynDiffAvgWeekMove']
      ? { calcSynDiffAvgWeekMove: multiplyBy100(row['calcSynDiffAvgWeekMove']) }
      : {}),
    ...(row['calcSynDiffAvgYear']
      ? { calcSynDiffAvgYear: multiplyBy100(row['calcSynDiffAvgYear']) }
      : {}),
    ...(row['calcSynDiffAvgYearMove']
      ? { calcSynDiffAvgYearMove: multiplyBy100(row['calcSynDiffAvgYearMove']) }
      : {}),
    ...(row['calcSynDiffSDYear']
      ? { calcSynDiffSDYear: multiplyBy100(row['calcSynDiffSDYear']) }
      : {}),
    ...(row['calcYieldBrutoAvgMonth']
      ? { calcYieldBrutoAvgMonth: multiplyBy100(row['calcYieldBrutoAvgMonth']) }
      : {}),
    ...(row['calcYieldBrutoAvgMonthMove']
      ? { calcYieldBrutoAvgMonthMove: multiplyBy100(row['calcYieldBrutoAvgMonthMove']) }
      : {}),
    ...(row['calcYieldBrutoAvgWeek']
      ? { calcYieldBrutoAvgWeek: multiplyBy100(row['calcYieldBrutoAvgWeek']) }
      : {}),
    ...(row['calcYieldBrutoAvgWeekMove']
      ? { calcYieldBrutoAvgWeekMove: multiplyBy100(row['calcYieldBrutoAvgWeekMove']) }
      : {}),
    ...(row['calcYieldBrutoAvgYear']
      ? { calcYieldBrutoAvgYear: multiplyBy100(row['calcYieldBrutoAvgYear']) }
      : {}),
    ...(row['calcYieldBrutoAvgYearMove']
      ? { calcYieldBrutoAvgYearMove: multiplyBy100(row['calcYieldBrutoAvgYearMove']) }
      : {}),
    ...(row['calcYieldBrutoSDYear']
      ? { calcYieldBrutoSDYear: multiplyBy100(row['calcYieldBrutoSDYear']) }
      : {}),
    ...(row['viewYieldBrutoAvgMonth']
      ? { viewYieldBrutoAvgMonth: multiplyBy100(row['viewYieldBrutoAvgMonth']) }
      : {}),
    ...(row['viewYieldBrutoAvgWeek']
      ? { viewYieldBrutoAvgWeek: multiplyBy100(row['viewYieldBrutoAvgWeek']) }
      : {}),
    ...(row['viewYieldBrutoAvgYear']
      ? { viewYieldBrutoAvgYear: multiplyBy100(row['viewYieldBrutoAvgYear']) }
      : {}),
    ...(row['viewYieldBrutoMinusLinkedAssetCurrent']
      ? {
          viewYieldBrutoMinusLinkedAssetCurrent: multiplyBy100(
            row['viewYieldBrutoMinusLinkedAssetCurrent'],
          ),
        }
      : {}),
    ...(row['viewYieldBrutoMinusLinkedAssetAvgMonth']
      ? {
          viewYieldBrutoMinusLinkedAssetAvgMonth: multiplyBy100(
            row['viewYieldBrutoMinusLinkedAssetAvgMonth'],
          ),
        }
      : {}),
    ...(row['viewYieldBrutoMinusLinkedAssetAvgWeek']
      ? {
          viewYieldBrutoMinusLinkedAssetAvgWeek: multiplyBy100(
            row['viewYieldBrutoMinusLinkedAssetAvgWeek'],
          ),
        }
      : {}),
    ...(row['viewYieldBrutoMinusLinkedAssetAvgYear']
      ? {
          viewYieldBrutoMinusLinkedAssetAvgYear: multiplyBy100(
            row['viewYieldBrutoMinusLinkedAssetAvgYear'],
          ),
        }
      : {}),
    ...(row['calcYieldBrutoMoovers']
      ? { calcYieldBrutoMoovers: multiplyBy100(row['calcYieldBrutoMoovers']) }
      : {}),
    ...(row['calcSynDiffMoovers']
      ? { calcSynDiffMoovers: multiplyBy100(row['calcSynDiffMoovers']) }
      : {}),
    ...(row['viewYieldBrutoLastDay']
      ? { viewYieldBrutoLastDay: multiplyBy100(row['viewYieldBrutoLastDay']) }
      : {}),
    ...(row['viewSynDiffLastDay']
      ? { viewSynDiffLastDay: multiplyBy100(row['viewSynDiffLastDay']) }
      : {}),
    ...(row['alert4'] ? { alert4: multiplyBy100(row['alert4']) } : {}),
    ...(row['alert8'] ? { alert8: multiplyBy100(row['alert8']) } : {}),
    ...(row['baseRateChangePercentage']
      ? { baseRateChangePercentage: multiplyBy100(row['baseRateChangePercentage']) }
      : {}),
    ...(row['beginOfYearYield']
      ? { beginOfYearYield: multiplyBy100(row['beginOfYearYield']) }
      : {}),
    ...(row['yesterdayYield'] ? { yesterdayYield: multiplyBy100(row['yesterdayYield']) } : {}),
    ...(row['annualInterest'] ? { annualInterest: multiplyBy100(row['annualInterest']) } : {}),
  };
};
