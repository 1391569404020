import { format } from 'date-fns';
import { isNull, isUndefined } from 'lodash-es';
import { convertToDate, dateFormat, isDateLike } from 'utils/dates';
import { Paths, Primitive } from 'utils/types';

const isEmptyPrimitive = (value: Primitive) => {
  return [isNull(value), isUndefined(value), value === ''].some(Boolean);
};

class MergeFilters {
  private value: Array<string | undefined>;
  constructor(value: Array<string | undefined>) {
    this.value = value.filter((v) => !isEmptyPrimitive(v));
  }
  join(separator: string) {
    if (this.value.length === 0) {
      return undefined;
    }
    return createStart(this.value.length, this.value.join(separator));
  }
}

export type ValueCreator<V = any | any[]> = (name: string, value: V) => string | undefined;

export const select = <T extends Record<string, any> = Record<string, any>>(
  ...args: (Paths<T> | string)[]
) => {
  return args
    .join(',')
    .replace(/  +/g, ' ') // remove extra spaces
    .replace(/\n/gm, '') // remove new lines
    .replace(' .', '.'); // remove spaces for methods
};

export interface DynamicOrder<F extends string = string> {
  field: F;
  order: 'desc' | 'asc' | null;
}

function _orderBy(order: DynamicOrder): string | undefined;
function _orderBy(name: string, order?: DynamicOrder['order']): string | undefined;
function _orderBy(name: any, order?: any): string | undefined {
  let field = '';
  let fieldOrder = '';
  if (name && typeof name === 'object') {
    field = name.field;
    fieldOrder = name.order;
  } else {
    field = name;
    fieldOrder = order;
  }

  if (!fieldOrder) {
    return undefined;
  }

  const names = field.split(',').filter(Boolean);

  if (names.length === 0) {
    return undefined;
  }

  return names.map((name) => [name, fieldOrder].join(' ')).join(',');
}

export const orderBy = _orderBy;

const escapeSpecialChars = (str: string) => {
  return str.replace(/([.*+?^${}()|[\]\\'"`])/g, '\\$1');
};
const prepareValue = (value?: string) => {
  return escapeSpecialChars(String(value).trim());
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query === '' ? undefined : query;
};

export const makeFilter = <T extends Record<string, any>>(
  name: Paths<T> | Paths<T>[],
  value: any | any[],
  valueCreator: ValueCreator,
): string | undefined => {
  const names = Array.isArray(name) ? name : [name];

  if (value === undefined) {
    return undefined;
  }

  const values = names.map((_n) => valueCreator(String(_n), value)).filter(Boolean);

  return createStart(values.length, values.join('||'));
};

export const mergeFilters = (...filters: (string | undefined)[]) => {
  return new MergeFilters(filters);
};

// Value creators
export const more: ValueCreator = (name, value) => {
  return `${name}>${value}`;
};
export const moreOrEquals: ValueCreator = (name, value) => {
  if (isEmptyPrimitive(value)) return undefined;

  return `${name}>=${value}`;
};
export const less: ValueCreator = (name, value) => {
  if (isEmptyPrimitive(value)) return undefined;
  return `${name}<${value}`;
};
export const lessOrEquals: ValueCreator = (name, value) => {
  if (isEmptyPrimitive(value)) return undefined;
  return `${name}<=${value}`;
};
export const equals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}==${innerValue}`;
};
export const notEquals: ValueCreator = (name, value) => {
  let innerValue = value;

  if (typeof value === 'string') {
    innerValue = `"${prepareValue(value)}"`;
  }
  return `${name}!=${innerValue}`;
};
export const contains: ValueCreator = (name, value) => {
  if (isEmptyPrimitive(value)) return undefined;

  return `${name}.toLower().contains("${prepareValue(String(value).toLowerCase())}")`;
};

export const equalsSome: ValueCreator = (name, value: string[]) => {
  if (!Array.isArray(value)) return undefined;
  let values = value.filter(Boolean);
  if (values.length === 0) return undefined;

  let valueStr = values.map((val) => `"${prepareValue(val)}"`).join(',');
  return `(new[]{${valueStr}}).Contains(${name})`;
};
export const dateMore: ValueCreator = (name, value) => {
  return `(${name} > DateTime(${format(convertToDate(value), 'yyyy,MM,dd')}))`;
};
export const dateRangeISO: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  const range = value.filter(isDateLike);

  if (range.length !== 2) {
    return undefined;
  }

  const [start, end] = range;
  return [
    `(${name} >= DateTime("${convertToDate(start).toISOString()}")`,
    `${name} <= DateTime("${convertToDate(end).toISOString()}"))`,
  ].join('&&');
};
export const dateRangeZero: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  const range = value.filter(isDateLike);

  if (range.length !== 2) {
    return undefined;
  }

  const [start, end] = range;

  return [
    `(${name} >= DateTime(${dateFormat(start, 'yyyy,MM,dd,0,0,0')})`,
    `${name} <= DateTime(${dateFormat(end, 'yyyy,MM,dd,23,59,59')}))`,
  ].join('&&');
};

export const minMax: ValueCreator = (name, value) => {
  if (!Array.isArray(value)) return undefined;

  if (value.length < 2) {
    return undefined;
  }

  const [min, max] = value;

  const queryArray = [
    min !== null && min !== '' && min !== undefined && `${name} >= ${min}`,
    max !== null && max !== '' && max !== undefined && `${name} <= ${max}`,
  ].filter(Boolean);

  return createStart(queryArray.length, queryArray.join('&&'));
};
// decorators
export const decoratorIsNotNullable = <V>(creator: ValueCreator<V>): ValueCreator<V> => {
  const result: ValueCreator = (name, value) => {
    if (isEmptyPrimitive(value)) return undefined;
    return creator(name, value);
  };
  return result;
};
export const decoratorIsNumber = (creator: ValueCreator<number>) => {
  const result = (name: string, value: number) => {
    if ([!isFinite(value as any)].some(Boolean)) return undefined;
    return creator(name, value);
  };
  return decoratorIsNotNullable(result);
};
export const decoratorStringify = (creator: ValueCreator): ValueCreator => {
  const result: ValueCreator = (name, value) => {
    return creator(name, value ? String(value) : value);
  };
  return decoratorIsNotNullable(result);
};
export const decoratorValueArray = (creator: ValueCreator, separator = '||') => {
  return ((name, value) => {
    if (!Array.isArray(value)) return undefined;
    if (value.length === 0) return undefined;
    return createStart(
      value.length,
      value.map((v) => makeFilter(name, v, creator)).join(separator),
    );
  }) as ValueCreator;
};

export const decoratorDivided = (creator: ValueCreator<number>): ValueCreator<number> => {
  const numberCreator = decoratorIsNumber(creator);

  return (name, value) => {
    const newVal = Number((value / 100).toFixed(2));
    return numberCreator(name, newVal);
  };
};
