import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatType } from '__generated__/api';
import { addMonths, startOfMonth } from 'date-fns';
import { DynamicOrder } from 'utils/dynamic';
import { Unset } from 'utils/numbers';
import { selectItemsAll, toggleItem } from 'utils/other';
import { actionReportGrossDeltaLinkedFetch } from './actions';
import { IReportGrossDeltaLinkedItem, PREFIX } from './helpers';

export interface ReportDeltaLinkedFilters {
  date: string[];
  bonds: string[];
  issuers: string[];

  grossDuration: Unset<number>[];
  value: Unset<number>[];

  equityTypes: string[];
  branches: string[];
  midroogRatingGroups: string[];
  midroogRatings: string[];
}

interface InitState {
  isInit: boolean;
  filters: ReportDeltaLinkedFilters;
  data: Array<IReportGrossDeltaLinkedItem>;
  isLoading: boolean;
  isGroupByRating: boolean;
  statType: IStatType;
  symbols: number[];
  orderBy: DynamicOrder;
}

export const initState = (): InitState => {
  return {
    isInit: false,
    isLoading: false,
    isGroupByRating: false,
    statType: IStatType.Avg,
    data: [],
    filters: {
      // backend
      date: [
        startOfMonth(addMonths(new Date(), -4)).toISOString(),
        startOfMonth(addMonths(new Date(), 1)).toISOString(),
      ],

      grossDuration: [0, 30],
      value: [0, 20],
      // frontend

      bonds: [],
      issuers: [],
      equityTypes: [],
      branches: [],
      midroogRatingGroups: [],
      midroogRatings: [],
    },
    symbols: [],
    orderBy: {
      field: 'symbol',
      order: null,
    },
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    init() {},
    destroy() {},
    toggleGrouped(state) {
      state.isGroupByRating = !state.isGroupByRating;
    },
    setFilters(state, action: PayloadAction<ReportDeltaLinkedFilters>) {
      state.filters = action.payload;
    },
    toggleItem(state, action: PayloadAction<{ id: string }>) {
      toggleItem(state.data, action.payload.id);
    },
    toggleAll(state, action: PayloadAction<{ value: boolean }>) {
      selectItemsAll(state.data, action.payload);
    },
    toggleGroupByRating(state) {
      state.isGroupByRating = !state.isGroupByRating;
    },
    applySymbols(state, action: PayloadAction<number[]>) {
      state.symbols = action.payload;
    },
    setOrder(state, action: PayloadAction<DynamicOrder>) {
      state.orderBy = action.payload;
    },
    setStatType(state, action: PayloadAction<IStatType>) {
      state.statType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionReportGrossDeltaLinkedFetch.pending, (state, action) => {
        state.isLoading = true;
        state.isInit = true;
      })
      .addCase(actionReportGrossDeltaLinkedFetch.fulfilled, (state, action) => {
        state.symbols = [];
        state.data = [];

        state.isLoading = false;
        action.payload.forEach((item) => {
          state.data.push({ ...item, __isSelected: true });
          state.symbols.push(item.symbol);
        });
      })
      .addCase(actionReportGrossDeltaLinkedFetch.rejected, (state, action) => {
        if (action.meta.aborted) {
          return state;
        }
        state.isLoading = false;
      });
  },
});
export const actionsReportGrossDeltaLinked = slice.actions;
export const reducerReportGrossDeltaLinked = slice.reducer;
