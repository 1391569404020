import { isNull, isNumber } from 'lodash-es';

export type Unset<T = string> = T | null | undefined;

export interface IRenderNumber {
  row: any;
  field: string | number | symbol;
  precision?: number;
  isPercentage?: boolean;
  isMultiply?: boolean;
}

interface Options {
  x: number;
  y0: number;
  y1: number;
  x0: number;
  x1: number;
}

export const interpolation = (options: Options) => {
  const { x, y1, y0, x0, x1 } = options;
  return y0 + ((y1 - y0) / (x1 - x0)) * (x - x0);
};

export const numberFormat = (count: number, options?: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat('en-US', options).format(count);

export const numberUS = (num: Unset<number | string>, precision?: number) => {
  if (!isNumber(num)) {
    return '';
  }

  return Number(num).toLocaleString('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};

export const numberToExcel = ({
  value,
  precision,
  isPercentage,
}: {
  value: Unset<number | string>;
  precision?: number;
  isPercentage?: boolean;
}) => {
  let _isPercentage = isPercentage;

  if (isNull(value) || value === '') {
    return '';
  }

  let _value = Number(value);

  if (typeof value === 'string') {
    const percentRegex = new RegExp('%', 'g');
    const commaRegex = new RegExp(',', 'g');

    if (value.includes('%') && !value.includes(',')) {
      _isPercentage = true;
      _value = Number(value.replace(percentRegex, ''));
    }

    if (value.includes('%') && value.includes(',')) {
      _isPercentage = true;
      const stringWithoutPercentage = value.replace(percentRegex, '');
      _value = Number(stringWithoutPercentage.replace(commaRegex, ''));
    }

    if (!value.includes('%') && value.includes(',')) {
      _value = Number(value.replace(commaRegex, ''));
    }
  }

  if (isNaN(_value)) {
    return '';
  }

  if (precision) {
    _value = Number.parseFloat(_value.toFixed(precision));
  }

  if (_isPercentage) {
    const format = precision ? `0.${'0'.repeat(precision)}%` : '0.00%';

    return {
      t: 'n',
      v: _value / 100,
      z: format,
    };
  }

  return _value;
};

export const multiplyBy100 = (value: string | number | null | undefined) => {
  if (!value && typeof value !== 'number') {
    return '';
  }

  const numberValue = Number(value);
  const result = numberValue * 100;

  return Number(result.toFixed(2));
};

export function isNumeric(value: any): value is string {
  return /^-?\d+$/.test(value);
}
