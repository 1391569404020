import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { isEqual, orderBy } from 'lodash-es';
import { apiBondsHelper } from 'services/bonds-helper';
import { AppState } from '../index';

const selectState = (state: AppState) => state.reportYieldExtreme;

const selectFilters = createSelector(selectState, (state) => {
  return state.filters;
});
const selectGroupByRating = createSelector(selectState, ({ isGroupByRating }) => {
  return isGroupByRating;
});

const selectData = createSelector(selectState, (state) => {
  return state.data;
});
const selectOrder = createSelector(selectState, (state) => {
  return state.orderBy;
});

const selectSources = createSelector(selectData, (rows) => {
  const mapMidroogRanks = new Map<string, { id: string; title: string }>();
  const mapMidroogOutlook = new Map<string, { id: string; title: string }>();

  const mapMaalotRanks = new Map<string, { id: string; title: string }>();
  const mapMaalotOutlook = new Map<string, { id: string; title: string }>();

  const mapSymbols = new Map<number, { id: string; name: string; symbol: number }>();

  const mapIssuers = new Map<string, { id: string; title: string; issuerNum: number }>();

  const mapCountries = new Map<string, { id: string; title: string }>();

  rows.forEach((row) => {
    if (row.symbol && !mapSymbols.has(row.symbol)) {
      mapSymbols.set(row.symbol, {
        id: String(row.id),
        name: String(row.name),
        symbol: row.symbol,
      });
    }

    if (row.issuerNum && !mapIssuers.has(row.issuerNum)) {
      mapIssuers.set(row.issuerNum, {
        id: String(row.issuerName),
        title: String(row.issuerName),
        issuerNum: Number(row.issuerNum),
      });
    }

    if (row.bondMidroogRank && !mapMidroogRanks.has(row.bondMidroogRank)) {
      const key = row.bondMidroogRank;
      mapMidroogRanks.set(key, { id: key, title: key });
    }
    if (row.bondMidroogOutlook && !mapMidroogOutlook.has(row.bondMidroogOutlook)) {
      const key = row.bondMidroogOutlook;
      mapMidroogOutlook.set(key, { id: key, title: key });
    }

    if (row.bondMaalotRank && !mapMaalotRanks.has(row.bondMaalotRank)) {
      const key = row.bondMaalotRank;
      mapMaalotRanks.set(key, { id: key, title: key });
    }
    if (row.bondMaalotOutlook && !mapMaalotOutlook.has(row.bondMaalotOutlook)) {
      const key = row.bondMaalotOutlook;
      mapMaalotOutlook.set(key, { id: key, title: key });
    }
    if (row.countryOfIncorporation && !mapCountries.has(row.countryOfIncorporation)) {
      const key = row.countryOfIncorporation;
      mapCountries.set(key, { id: key, title: key });
    }
  });

  return {
    midroogRanks: Array.from(mapMidroogRanks.values()),
    midroogOutlook: Array.from(mapMidroogOutlook.values()),

    maalotRanks: Array.from(mapMaalotRanks.values()),
    maalotOutlook: Array.from(mapMaalotOutlook.values()),

    symbols: Array.from(mapSymbols.values()),
    issuers: Array.from(mapIssuers.values()),
    countries: Array.from(mapCountries.values()),
  };
});

const selectDataFiltered = createSelector(selectData, selectFilters, (rows, filters) => {
  return rows.filter((row) => {
    const res: Array<boolean> = [];

    const handleArrayIncludes = (arr: string[], rowValue: any) => {
      if (arr.length > 0) {
        const includes = arr.includes(String(rowValue));
        res.push(includes);
      }
    };

    handleArrayIncludes(filters.bonds, row.symbol);
    handleArrayIncludes(filters.issuers, row.issuerName);
    handleArrayIncludes(filters.countryNames, row.countryOfIncorporation);
    handleArrayIncludes(filters.bondMidroogRanks, row.bondMidroogRank);
    handleArrayIncludes(filters.bondMidroogOutlooks, row.bondMidroogOutlook);
    handleArrayIncludes(filters.bondMaalotRanks, row.bondMaalotRank);
    handleArrayIncludes(filters.bondMaalotOutlooks, row.bondMaalotOutlook);

    return res.every(Boolean);
  });
});

const selectDataSorted = createSelector(selectDataFiltered, selectOrder, (rows, order) => {
  if (!order.order) {
    return rows;
  }

  return orderBy(rows, [order.field], [order.order]);
});

const selectDataSelected = createSelector(selectDataFiltered, (rows) => {
  return rows.filter((row) => {
    return row.__isSelected;
  });
});
const selectSelectedSymbols = createSelector(selectDataSelected, (rows) => {
  return rows.map(({ symbol }) => symbol);
});

const selectedSymbols = createSelector(selectState, ({ symbols }) => {
  return symbols;
});

export const selectIsApplyAble = createSelector(
  selectSelectedSymbols,
  selectedSymbols,
  (selectedSymbols, symbols) => {
    if (symbols.length === 0) return false;

    const res = isEqual(selectedSymbols, symbols);

    return !res;
  },
);

const selectAnalyticsStatsArgs = createSelector(
  selectedSymbols,
  selectFilters,
  selectGroupByRating,
  (symbols, filters, isGrouped) => {
    return { symbols: symbols, date: filters.date, ratingGroup: isGrouped };
  },
);

const selectStatuses = createSelector(selectState, ({ isLoading, isInit }) => {
  return { isLoading, isInit };
});

const selectAnalyticsLoading = createSelector(
  (state: AppState) => state,
  selectAnalyticsStatsArgs,
  (state, args) => {
    const items = [
      apiBondsHelper.endpoints.getBondGelemReportStatsYieldExtreme.select(args)(state),
    ];

    return items.some(({ status }) => status === QueryStatus.pending);
  },
);

export const selectorsReportYieldExtreme = {
  filters: selectFilters,
  orderBy: selectOrder,
  sources: selectSources,
  data: selectData,
  dataSelected: selectDataSelected,
  dataFiltered: selectDataSorted,
  loadingAnalytics: selectAnalyticsLoading,
  statuses: selectStatuses,
  statsArgs: selectAnalyticsStatsArgs,
  isGrouped: selectGroupByRating,
  isApplyAble: selectIsApplyAble,
};
