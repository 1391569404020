import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  DASHBOARD: createTypedRoute('/'),
  PROFILE: createTypedRoute('/profile'),

  ONLINE_DELTA: createTypedRoute('/online/delta'),
  ONLINE_YIELD: createTypedRoute('/online/yield'),
  ONLINE_BONDS: createTypedRoute('/online/bonds'),
  ONLINE_STOCKS: createTypedRoute('/online/stocks'),
  ONLINE_ALERTS: createTypedRoute('/online/alerts'),

  PORTFOLIO_DELTA: createTypedRoute('/portfolio/delta'),
  PORTFOLIO_YIELD: createTypedRoute('/portfolio/yield'),
  PORTFOLIO_BONDS: createTypedRoute('/portfolio/bond'),
  PORTFOLIO_STOCKS: createTypedRoute('/portfolio/stocks'),

  ALERTS_DELTA: createTypedRoute('/alerts/delta'),
  ALERTS_YIELD: createTypedRoute('/alerts/yield'),
  ALERTS_STOCK_PERFORMANCE: createTypedRoute('/alerts/stock-performance'),
  ALERTS_STOCK_YIELD: createTypedRoute('/alerts/stock-yield'),
  ALERTS_CONFIGURATION: createTypedRoute('/alerts/configuration'),
  ALERTS_HISTORY: createTypedRoute('/alerts/history'),

  RAW_DATA_BONDS: createTypedRoute('/raw/bonds'),
  RAW_DATA_STOCKS: createTypedRoute('/raw/stocks'),

  ARCHIVE_BONDS: createTypedRoute('/archive/bonds'),
  ARCHIVE_BONDS_RAW: createTypedRoute('/bonds/raw-data'),

  ARCHIVE_STOCKS: createTypedRoute('/archive/stocks'),
  ARCHIVE_STOCKS_RAW: createTypedRoute('/archive/stocks/raw-data'),

  ARCHIVE_MIDROOG_RATINGS: createTypedRoute('/archive/parameters/midroog-ratings'),
  ARCHIVE_MAALOT_RATINGS: createTypedRoute('/archive/parameters/maalot-ratings'),
  ARCHIVE_USERS: createTypedRoute('/archive/users'),
  ARCHIVE_LOGS: createTypedRoute('/archive/logs'),

  USERS: createTypedRoute('/users'),
  USER_PERMISSIONS: createTypedRoute('/permissions'),

  LABELS: createTypedRoute('/labels'),
  LANGUAGES: createTypedRoute('/languages'),
  EXCLUDE_DATES: createTypedRoute('/exclude-dates'),
  GTO_ENDPOINTS: createTypedRoute('/gto-endpoints'),
  FILLING_MISSING_DATE: createTypedRoute('/filling-missing-date'),
  DATA_ETL: createTypedRoute('/data-etl'),

  ISSUERS: createTypedRoute('/issuers'),
  BRANCHES: createTypedRoute('/branches'),
  LINKED_ASSETS: createTypedRoute('/linked-assets'),
  EQUITY_TYPES: createTypedRoute('/equity-types'),
  TRADE_STATUSES: createTypedRoute('/trade-statuses'),
  MIDROOG_RATINGS: createTypedRoute('/midroog-ratings'),
  MAALOT_RATINGS: createTypedRoute('/maalot-ratings'),
  ALERTS_PORTFOLIO_DEFAULT: createTypedRoute('/alerts-portfolio-default'),
  MIGRATIONS: createTypedRoute('/migrations'),

  REPORTS_PORTFOLIO_SUMMARY: createTypedRoute('/reports/portfolio-summary'),

  REPORT_YIELD_LINKED: createTypedRoute('/reports/yield/linked'),
  REPORT_YIELD_SHEKEL: createTypedRoute('/reports/yield/shekel'),
  REPORT_DELTA_LINKED: createTypedRoute('/reports/delta/linked'),
  REPORT_DELTA_SHEKEL: createTypedRoute('/reports/delta/shekel'),

  REPORT_GROSS_YIELD_LINKED: createTypedRoute('/reports/gross/yield/linked'),
  REPORT_GROSS_YIELD_SHEKEL: createTypedRoute('/reports/gross/yield/shekel'),
  REPORT_GROSS_DELTA_LINKED: createTypedRoute('/reports/gross/delta/linked'),
  REPORT_GROSS_DELTA_SHEKEL: createTypedRoute('/reports/gross/delta/shekel'),

  REPORT_YIELD_EXTREME: createTypedRoute('/reports/yield/extreme'),

  LOGS: createTypedRoute('/logs'),

  HELP: createTypedRoute('/help'),

  LOGIN: createTypedRoute<{ redirect?: string } | void>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),
};
