import {
  Action,
  combineReducers,
  configureStore,
  createListenerMiddleware,
  Middleware,
  ThunkAction,
} from '@reduxjs/toolkit';
import { PersistMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';
import { apiRtk } from 'utils/service';
import packages from '../../package.json';
import { reducerAccount, sagasAccounts } from './auth';
import { reducerProfileBonds, sagasProfileBonds } from './profile-bonds';
import { reducerProfileStocks, sagasProfileStocks } from './profile-stocks';

import { reducerReportDeltaLinked, sagasReportDeltaLinked } from './report-delta-linked';
import { reducerReportDeltaShekel, sagasReportDeltaShekel } from './report-delta-shekel';
import { reducerReportYieldExtreme, sagasReportYieldExtreme } from './report-yield-extreme';
import { reducerReportYieldLinked, sagasReportYieldLinked } from './report-yield-linked';
import { reducerReportYieldShekel, sagasReportYieldShekel } from './report-yield-shekel';

import {
  reducerReportGrossDeltaLinked,
  sagasReportGrossDeltaLinked,
} from './report-gross-delta-linked';
import {
  reducerReportGrossDeltaShekel,
  sagasReportGrossDeltaShekel,
} from './report-gross-delta-shekel';
import {
  reducerReportGrossYieldLinked,
  sagasReportGrossYieldLinked,
} from './report-gross-yield-linked';
import {
  reducerReportGrossYieldShekel,
  sagasReportGrossYieldShekel,
} from './report-gross-yield-shekel';

import { reducerTradingEtl, sagasTradingEtl } from './trading-etl';

const VERSION = parseInt(packages.version.replace(/\./gi, ''));

const migrateStore: PersistMigrate = (state) => {
  if (VERSION === state?._persist.version) {
    return Promise.resolve(state);
  } else {
    return Promise.resolve(undefined);
  }
};

const sagaMiddleware = createSagaMiddleware();
const listenerMiddleware = createListenerMiddleware();

const rootReducer = combineReducers({
  account: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'account',
      storage,
      whitelist: ['languageID'],
    },
    reducerAccount,
  ),
  reducerTradingEtl,
  profileBonds: reducerProfileBonds,
  profileStocks: reducerProfileStocks,

  reportYieldLinked: reducerReportYieldLinked,
  reportDeltaLinked: reducerReportDeltaLinked,
  reportYieldShekel: reducerReportYieldShekel,
  reportDeltaShekel: reducerReportDeltaShekel,

  reportYieldExtreme: reducerReportYieldExtreme,

  reportGrossYieldLinked: reducerReportGrossYieldLinked,
  reportGrossYieldShekel: reducerReportGrossYieldShekel,
  reportGrossDeltaLinked: reducerReportGrossDeltaLinked,
  reportGrossDeltaShekel: reducerReportGrossDeltaShekel,

  [apiRtk.reducerPath]: apiRtk.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware as Middleware, apiRtk.middleware, listenerMiddleware.middleware),
  devTools: import.meta.env.DEV,
});

export const persistor = persistStore(store);

function* rootSaga() {
  yield all([
    ...sagasAccounts,
    ...sagasTradingEtl,
    ...sagasProfileBonds,
    ...sagasProfileStocks,

    ...sagasReportYieldLinked,
    ...sagasReportYieldShekel,
    ...sagasReportDeltaLinked,
    ...sagasReportDeltaShekel,

    ...sagasReportGrossYieldLinked,
    ...sagasReportGrossYieldShekel,
    ...sagasReportGrossDeltaLinked,
    ...sagasReportGrossDeltaShekel,
    ...sagasReportYieldExtreme,
  ]);
}

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export type AppAsyncThunkConfig = {
  state: AppState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};
